const createConfig = (user, lead, extra) => ({
    ADMIN: {
        primary: [
            {
                name: "Leads",
                link: "/leads",
                id: "leads",
                icon: "leads",
            },
            {
                name: "Agents",
                link: "/agents",
                id: "agents",
                icon: "agents",
            },
            {
                name: "Insurers",
                link: "/insurers",
                id: "insurers",
                icon: "insurer",
            },
            {
                name: "New Estimate",
                link: "/newLead",
                id: "newEst",
                icon: "newEstimate",
            },
            {
                name: "Master Policy",
                link: "/masterPolicy",
                id: "masterPolicy",
                icon: "masterPolicy",
            },
            {
                name: "Hospital List",
                link: "/hospitalList",
                id: "uploadHospital",
                icon: "uploadHospital",
            },
        ],
        secondary: [
            {
                name: "Home",
                link: "/home",
                id: "home",
                icon: "home",
            },
            {
                name: "Members",
                link: "/members",
                id: "members",
                icon: "members",
            },
            {
                name: "My Policies",
                link: "/plans",
                id: "plans",
                icon: "plans",
            },
            {
                name: "Details",
                link: "/details",
                id: "details",
                icon: "details",
                user: "admin",
            },
            ...(window.location.hostname === "kmdastur.imt.care" ||
            window.location.hostname === "insurance.kmdastur.com" ||
            window.location.hostname === "kmddemo.imt.care"
                ? []
                : [
                      {
                          name: "Hospitals",
                          link: "/hospitals",
                          id: "hospitals",
                          icon: "hospitals",
                      },
                  ]),
            {
                name: "Payments",
                link: "/payments",
                id: "payments",
                user: "mAdmin",
                icon: "payments",
            },
            {
                name: "Broker",
                link: "",
                id: "admin",
                icon: "admin",
                user: "admin",
            },
            {
                name: "Users",
                link: "/users",
                id: "users",
                user: "admin",
                icon: "users",
            },

            {
                name: "Claims",
                link: "/claims",
                id: "claims",
                user: "admin",
                icon: "claims",
            },
            {
                name: "Actions",
                link: "/admin",
                user: "admin",
                id: "actions",
                icon: "actions",
            },
            {
                name: "Params",
                link: "/params",
                user: "admin",
                id: "params",
                icon: "params",
            },
            {
                name: "Reports",
                link: "",
                user: "report",
                id: "report",
                icon: "report",
            },
            {
                name: "Claim Report",
                link: "/claim-report",
                user: "report",
                id: "reports",
                icon: "claimreport",
            },
            {
                name: "App Usage",
                link: "/app-usage-report",
                user: "report",
                id: "reports",
                icon: "appusage",
            },
            {
                name: "Enrollment",
                link: "/enrollment-report",
                user: "report",
                id: "reports",
                icon: "enrollment",
            },
            {
                name: "Email Data",
                link: "/emaildata-report",
                user: "report",
                id: "reports",
                icon: "emailData",
            },
        ],
    },
    AGENT: {
        primary: [
            {
                name: "Leads",
                link: "/leads",
                id: "leads",
                icon: "leads",
            },
            {
                name: "New Estimate",
                link: "/newLead",
                id: "newEst",
                icon: "newEstimate",
            },
        ],
        secondary: [
            {
                name: "Home",
                link: "/home",
                id: "home",
                icon: "home",
            },
            {
                name: "Members",
                link: "/members",
                id: "members",
                icon: "members",
            },
            {
                name: "My Policies",
                link: "/plans",
                id: "plans",
                icon: "plans",
            },
            ...(window.location.hostname === "kmdastur.imt.care" ||
            window.location.hostname === "insurance.kmdastur.com" ||
            window.location.hostname === "kmddemo.imt.care"
                ? []
                : [
                      {
                          name: "Hospitals",
                          link: "/hospitals",
                          id: "hospitals",
                          icon: "hospitals",
                      },
                  ]),
            {
                name: "Details",
                link: "/details",
                id: "details",
                icon: "details",
                user: "admin",
            },
            {
                name: "Payments",
                link: "/payments",
                id: "payments",
                user: "mAdmin",
                icon: "payments",
            },
            {
                name: "Broker",
                link: "",
                id: "admin",
                icon: "admin",
                user: "admin",
            },
            {
                name: "Users",
                link: "/users",
                id: "users",
                user: "admin",
                icon: "users",
            },
            {
                name: "Claims",
                link: "/claims",
                id: "claims",
                user: "admin",
                icon: "claims",
            },
            {
                name: "Actions",
                link: "/admin",
                user: "admin",
                id: "actions",
                icon: "actions",
            },
            {
                name: "Params",
                link: "/params",
                user: "admin",
                id: "params",
                icon: "params",
            },
            {
                name: "Reports",
                link: "",
                user: "report",
                id: "reports",
                icon: "reports",
            },
            {
                name: "Claim Report",
                link: "/claim-report",
                user: "report",
                id: "reports",
                icon: "claimreport",
            },
            {
                name: "App Usage",
                link: "/app-usage-report",
                user: "report",
                id: "reports",
                icon: "appusage",
            },
            {
                name: "Enrollment",
                link: "/enrollment-report",
                user: "report",
                id: "reports",
                icon: "enrollment",
            },
            {
                name: "Email Data",
                link: "/emaildata-report",
                user: "report",
                id: "reports",
                icon: "emailData",
            },
        ],
    },
    CUSTOMER: {
        primary: [
            {
                name: "Companies",
                link: "/leads",
                id: "leads",
                icon: "leads",
            },
            {
                name: "New Estimate",
                link: "/newLead",
                id: "newEst",
                icon: "newEstimate",
            },
        ],
        secondary: [
            {
                name: "Home",
                link: "/home",
                id: "home",
                icon: "home",
            },
            {
                name: "Members",
                link: "/members",
                id: "members",
                icon: "members",
            },
            {
                name: "My Policies",
                link: "/plans",
                id: "plans",
                icon: "plans",
            },
            ...(window.location.hostname === "kmdastur.imt.care" ||
            window.location.hostname === "insurance.kmdastur.com" ||
            window.location.hostname === "kmddemo.imt.care"
                ? []
                : [
                      {
                          name: "Hospitals",
                          link: "/hospitals",
                          id: "hospitals",
                          icon: "hospitals",
                      },
                  ]),
            {
                name: "Payments",
                link: "/payments",
                id: "payments",
                icon: "payments",
            },
            {
                name: "Claims",
                link: "/claims",
                id: "claims",
                // user: "admin",
                icon: "claims",
            },
        ],
    },
    EMPLOYER: {
        primary: [
            ...(user?.multipleLeads
                ? [
                      {
                          name: "Companies",
                          link: "/leads",
                          id: "leads",
                          icon: "leads",
                      },
                  ]
                : []),
        ],
        secondary: [
            {
                name: "Home",
                link: "/home",
                id: "home",
                icon: "home",
            },
            {
                name: "Members",
                link: "/members",
                id: "members",
                icon: "members",
            },
            {
                name: "My Policies",
                link: "/plans",
                id: "plans",
                icon: "plans",
            },
            ...(window.location.hostname === "kmdastur.imt.care" ||
            window.location.hostname === "insurance.kmdastur.com" ||
            window.location.hostname === "kmddemo.imt.care"
                ? []
                : [
                      {
                          name: "Hospitals",
                          link: "/hospitals",
                          id: "hospitals",
                          icon: "hospitals",
                      },
                  ]),
            {
                name: "Payments",
                link: "/payments",
                id: "payments",
                icon: "payments",
            },
            {
                name: "Claims",
                link: "/claims",
                id: "claims",
                // user: "admin",
                icon: "claims",
            },
            {
                name: "Users",
                link: "/users",
                id: "users",
                // user: "admin",
                icon: "users",
            },
            {
                name: "Reports",
                link: "",
                user: "report",
                id: "report",
                icon: "report",
            },
            {
                name: "Claim Report",
                link: "/claim-report",
                user: "report",
                id: "reports",
                icon: "claimreport",
            },
            {
                name: "App Usage",
                link: "/app-usage-report",
                user: "report",
                id: "reports",
                icon: "appusage",
            },
            {
                name: "Enrollment",
                link: "/enrollment-report",
                user: "report",
                id: "reports",
                icon: "enrollment",
            },
        ],
    },
    USER: {
        primary: [],
        secondary: [
            {
                name: "Home",
                link: "/home",
                id: "home",
                icon: "home",
            },
            {
                name: "My Policies",
                link: "/plans",
                id: "plans",
                icon: "plans",
            },
            ...(window.location.hostname === "kmdastur.imt.care" ||
            window.location.hostname === "insurance.kmdastur.com" ||
            window.location.hostname === "kmddemo.imt.care"
                ? []
                : [
                      {
                          name: "Hospitals",
                          link: "/hospitals",
                          id: "hospitals",
                          icon: "hospitals",
                      },
                  ]),
            ...(extra.wallet
                ? [
                      {
                          name: "Wallet",
                          link: "/home?wallet=true",
                          id: "wallet",
                          icon: "wallet",
                      },
                  ]
                : []),
            {
                name: "Claims",
                link: "/claims",
                id: "claims",
                //   user: "admin",
                icon: "claims",
            },
        ],
    },
    USR: {
        primary: [],
        secondary: [
            {
                name: "Home",
                link: "/home",
                id: "home",
                icon: "home",
            },
        ],
    },

    Default: {
        primary: [],
        secondary: [],
    },
    CUSTOM: {
        primary: [],
        secondary: [
            ...(window.location.hostname === "kmdastur.imt.care" ||
            window.location.hostname === "insurance.kmdastur.com" ||
            window.location.hostname === "kmddemo.imt.care"
                ? []
                : [
                      {
                          name: "Hospitals",
                          link: "/hospitals",
                          id: "hospitals",
                          icon: "hospitals",
                      },
                  ]),
            {
                name: "Broker",
                link: "",
                id: "admin",
                icon: "admin",
                user: "admin",
            },
            {
                name: "Claims",
                link: "/claims",
                id: "claims",
                user: "admin",
                icon: "claims",
            },
            {
                name: "Actions",
                link: "/admin",
                user: "admin",
                icon: "actions",
            },
        ],
    },
});

export default createConfig;
